<template>
  <div>
    <page-header
      title="Participations aux évènements" icon="fa fa-elevator"
      :links="pageLinks"
    ></page-header>
    <div v-if="!canView">
      <b>Vous n'êtes pas autorisé à voir cette page</b>
    </div>
    <div v-else>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Types" label-for="selectedDateType">
            <b-select v-model="selectedDateType" name="selectedDateType">
              <b-select-option :value="null">Tous</b-select-option>
              <b-select-option :value="obj" v-for="obj of dateTypes" :key="obj.id">
                {{ obj.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col></b-col>
        <b-col cols="4">
          <date-frame-selector
            :start-date="startDate"
            :end-date="endDate"
            @change="onDateRangeChanged"
          >
          </date-frame-selector>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <loading-gif :loading-name="loadingName"></loading-gif>
          <div ref="pdfAndXls" v-if="!isLoading(loadingName)">
            <x-table
              :columns="columns"
              :items="items"
              show-counter
              show-footer
              :verbose-name="'évènement' + (selectedDateType ? (' ' + selectedDateType.name) : '')"
              :verbose-name-plural="'évènements' + (selectedDateType ? (' ' + selectedDateType.name) : '')"
            ></x-table>
          </div>
        </b-col>
      </b-row>
    </div>
    <add-activity-date-modal
      modal-id="bv-add-activity-participation-modal"
      :editEvent="editEvent"
      :participations="true"
      @done="onEventAdded"
      @updated="onEventUpdated"
    ></add-activity-date-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import XTable from '@/components/Controls/Table/Table.vue'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import AddActivityDateModal from '@/components/Agenda/AddActivityDateModal.vue'
import { BackendApi, openDocument } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeActivityDate, makeAgendaDateType } from '@/types/agenda'
import { sum } from '@/utils/math'
import { dateToString } from '@/filters/texts'
import { slugify } from '@/utils/strings'

export default {
  name: 'place-list',
  mixins: [BackendMixin],
  components: {
    LoadingGif,
    AddActivityDateModal,
    DateFrameSelector,
    XTable,
    PageHeader,
  },
  data() {
    return {
      dateTypes: [],
      selectedDateType: null,
      dates: [],
      participations: new Map(),
      columns: [],
      startDate: null,
      endDate: null,
      loadingName: 'participations-dates',
      editEvent: null,
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    selectedDateType: function() {
      this.columns = this.getColumns()
    },
  },
  computed: {
    canAdd() {
      return this.hasAllPerms(
        ['agenda.add_activitydate', 'agenda.add_agendadateparticipation']
      )
    },
    canChange() {
      return this.hasAllPerms(
        ['agenda.change_activitydate', 'agenda.change_agendadateparticipation']
      )
    },
    canView() {
      return this.hasAllPerms(
        ['agenda.view_activitydate', 'agenda.view_agendadateparticipation']
      )
    },
    items() {
      let theDates = this.dates
      if (this.selectedDateType) {
        theDates = theDates.filter(elt => elt.dateTypeId === this.selectedDateType.id)
      }
      return theDates.map(this.makeItem)
    },
    pageLinks() {
      let cssClass = 'btn-secondary'
      const loading = this.isLoading(this.loadingName)
      const disabled = this.items.length === 0
      if (disabled || loading) {
        cssClass += ' disabled'
      }
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: cssClass,
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: cssClass,
        }
      ]
      if (this.canAdd) {
        links.push(
          {
            id: 3,
            label: 'Ajouter',
            callback: this.addOne,
            icon: 'fa fa-plus',
            cssClass: loading ? 'disabled btn-primary' : 'btn-primary',
          }
        )
      }
      return links
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      await this.loadDateTypes()
      this.columns = this.getColumns()
      await this.loadParticipationDates()
    },
    getColumns() {
      const columns = [
        {
          name: 'name',
          label: 'Nom',
        },
        {
          name: 'date',
          label: 'Date',
          dateFormat: 'DD/MM/YYYY',
          onClick: (item) => {
            router.push(
              { name: 'agenda-activity-dates', query: { date: dateToString(item.date, 'YYYY-MM-DD'), }, }
            )
          },
        },
        {
          name: 'startHour',
          label: 'De',
        },
        {
          name: 'endHour',
          label: 'à',
        },
        {
          name: 'place',
          label: 'Lieu',
        }
      ]
      if (this.selectedDateType) {
        for (let index = 0; index < this.selectedDateType.categories.length; index++) {
          const cat = this.selectedDateType.categories[index]
          columns.push(
            {
              name: 'cat' + index,
              label: cat.name,
              number: true,
              colFooterSum: true,
            }
          )
        }
      } else {
        const maxCol = Math.max(...(this.dateTypes.map(elt => elt.categories.length)))
        for (let index = 0; index < maxCol; index++) {
          columns.push(
            {
              name: 'cat' + index,
              label: 'Participation ' + (index + 1),
              number: true,
              colFooterSum: true,
            }
          )
        }
      }
      columns.push(
        {
          name: 'totalParticipations',
          label: 'Total',
          number: true,
          colFooterSum: true,
        }
      )
      if (this.canChange) {
        columns.push(
          {
            name: 'edit',
            hideFilter: true,
            label: '',
            title: 'Modifier',
            maxWidth: '30px',
            alignCenter: true,
            onClick: this.onEditEvent,
          }
        )
      }
      return columns
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadParticipationDates()
    },
    async loadDateTypes() {
      try {
        let url = '/agenda/api/agenda-date-types/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.dateTypes = resp.data.map(makeAgendaDateType)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadParticipationDates() {
      this.startLoading(this.loadingName)
      try {
        let url = '/agenda/api/all-date-participations/?'
        if (this.startDate) {
          url += '&start_date=' + dateToString(this.startDate, 'YYYY-MM-DD')
        }
        if (this.endDate) {
          url += '&end_date=' + dateToString(this.endDate, 'YYYY-MM-DD')
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.dates = resp.data.dates.map(makeActivityDate)
        const participations = new Map()
        for (const [key, value] of Object.entries(resp.data.participations)) {
          participations.set(+key, value)
        }
        this.participations = participations
        this.startDate = resp.data.start_date
        this.endDate = resp.data.end_date
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    makeItem(theDate) {
      const item = {
        id: theDate.id,
        name: theDate.label,
        date: theDate.startDateTime,
        startHour: dateToString(theDate.startDateTime, 'HH:mm'),
        endHour: dateToString(theDate.endDateTime, 'HH:mm'),
        totalParticipations: this.getTotalParticipations(theDate),
        place: theDate.place.name,
        obj: theDate,
        edit: '<i class="fa fa-edit no-print small-text"></i>',
      }
      const dateTypeIndex = this.dateTypes.map(elt => elt.id).indexOf(theDate.dateTypeId)
      if (dateTypeIndex >= 0) {
        const theDateType = this.dateTypes[dateTypeIndex]
        for (let index = 0; index < theDateType.categories.length; index++) {
          const cat = theDateType.categories[index]
          item['cat' + index] = this.getCategoryParticipations(cat, theDate)
        }
      }
      return item
    },
    getTotalParticipations(theDate) {
      if (this.participations.has(theDate.id)) {
        const items = this.participations.get(theDate.id)
        return sum(items.map(elt => elt.participation))
      }
      return 0
    },
    getCategoryParticipations(category, theDate) {
      if (this.participations.has(theDate.id)) {
        const items = this.participations.get(theDate.id)
        return sum(items.filter(elt => elt.category === category.id).map(elt => elt.participation))
      }
      return 0
    },
    async excelMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = that.selectedDateType ? slugify(that.selectedDateType.name) : 'evenements'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
    async printMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          let docUrl = '/documents/standard/<key>/pdf/'
          const docSlug = that.selectedDateType ? slugify(that.selectedDateType.name) : 'evenements'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
    addOne() {
      this.editEvent = null
      const that = this
      this.$nextTick(
        async function() {
          that.$bvModal.show('bv-add-activity-participation-modal')
        }
      )
    },
    onEditEvent(item) {
      this.editEvent = item.obj
      const that = this
      this.$nextTick(
        async function() {
          that.$bvModal.show('bv-add-activity-participation-modal')
        }
      )
    },
    onEventAdded() {
      this.init()
    },
    onEventUpdated() {
      this.init()
    },
  },
}
</script>

<style scoped>
tr.selected td,
tr:nth-of-type(odd).selected td,
tr:nth-of-type(even).selected td {
  background: #444;
  color: #fff;
}
.number {
  text-align: center;
}
.always-available {
  display: inline-block;
  background: #f8a500;
  padding: 2px 5px;
  color: #000;
  border-radius: 4px;
  font-size: 11px;
  vertical-align: middle;
}
</style>
